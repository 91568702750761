<template>
    <div
        :value="value"
        class="box"
        :class="{selected: selected}"
        @click="selectItem()">
        <div class="top">
            <div>
                <img
                    :src="image"
                    alt="">
            </div>
        </div>
        <div class="bottom">
            {{ name }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'LibraryBox',
    props: {
        name: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            required: true
        },
        multipleChoice: {
            type: Boolean,
            required: true
        },
        belongsTo:{
            type: String,
            required: true
        }
    },
    methods: {
        selectItem() {
            this.$emit('setSelection', this.value, this.multipleChoice, this.belongsTo);
        }
    }
};
</script>

<style lang="scss" scoped>
.box{
    width: 145px;
    height: 125px;
    background: white;
    border:  1px solid #dddddd;
    margin: 5px;
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
    vertical-align: top;
    &:hover{
        border: 1px solid #00A2EA;
    }
    &.selected{
        border: 4px solid #00A2EA;
    }
}
.top{
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img{
        width: 35px;
        height: auto;
        max-height: 35px;
    }
}
.bottom{
    height: 50%;
    padding:10% 5px 15% 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #757575;
    box-sizing: border-box;
}

</style>
