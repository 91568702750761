<template>
    <div class="selection">
        <div class="selection__columns">
            <div class="selection__right">
                <h1 class="selection__title">
                    Ad Library
                </h1>
                <div class="selection__desc">
                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                    Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
                    Cras justo odio, dapibus ac facilisis in, egestas eget quam. Etiam porta sem malesuada magna mollis euismod.
                </div>
                <!-- creative block -->
                <div class="creative">
                    <div class="creative__top">
                        <h3 class="creative__title">
                            CREATIVE STUDIO
                        </h3>
                        <div class="creative__desc">
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                        </div>
                    </div>
                    <div class="creative__bottom">
                        <a
                            href="#"
                            class="creative__link">Create your own ads</a>
                    </div>
                </div>
                <!-- end creative block -->
            </div>
            <div class="selection__left">
                <div
                    v-for="(filter, index) in filters"
                    :key="index"
                    class="selection__row">
                    <h2 class="selection__smalltitle">
                        {{ filter.section }} <span class="selection__notice">{{ filter.notice }}</span>
                    </h2>
                    <div class="selection__boxes">
                        <library-box
                            v-for="detail in filter.details"
                            :key="detail.value"
                            :value="detail.value"
                            :name="detail.name"
                            :image="detail.image"
                            :selected="detail.selected"
                            :multiple-choice="filter.multiple_choice"
                            :belongs-to="filter.group"
                            @setSelection="setSelection" />
                    </div>
                </div>
            </div>
        </div>

        <div class="selection__footer">
            <button
                class="selection__submit"
                @click="submitSelections()">
                LETS GO!
            </button>
        </div>
    </div>
</template>

<script>
import LibraryBox from '@/components/library/LibraryBox';

export default {
    name: 'LibrarySelection',
    title: 'Library Selection',
    components: {
        LibraryBox
    },
    data() {
        return {
            selectedItems: {},
            //Structure of filters can be like that. filters will be in vuex store ?
            filters: [
                {
                    section: 'Ad(s)',
                    notice: '',
                    multiple_choice: false,
                    group:'ads_group',
                    details: [{
                        name: 'Creative Ads',
                        value: 'creative_ads',
                        belongs_to: 'ads_group',
                        selected: true,
                        image: require('@/assets/icons/avt.svg')
                    },
                    {
                        name: 'Playbooks',
                        value: 'playbooks',
                        selected: false,
                        image: require('@/assets/icons/play.svg')
                    }]
                },
                {
                    section: 'Platforms',
                    notice: 'Select All That Apply',
                    multiple_choice: true,
                    group: 'platforms',
                    details: [{
                        name: 'Facebook',
                        value: 'facebook',
                        belongs_to: 'platforms',
                        selected: true,
                        image: require('@/assets/icons/facebook.svg')
                    },
                    {
                        name: 'Snapchat',
                        value: 'snapchat',
                        belongs_to: 'platforms',
                        selected: false,
                        image: require('@/assets/icons/snapchat.svg')
                    },
                    {
                        name: 'Pinterest',
                        value: 'pinterest',
                        belongs_to: 'platforms',
                        selected: true,
                        image: require('@/assets/icons/pinterest.svg')
                    },
                    {
                        name: 'Tiktok',
                        value: 'tiktok',
                        belongs_to: 'platforms',
                        selected: false,
                        image: require('@/assets/icons/tiktok.svg')
                    },
                    {
                        name: 'Microsoft',
                        value: 'microsoft',
                        belongs_to: 'platforms',
                        selected: false,
                        image: require('@/assets/icons/microsoft_light.svg')
                    }]
                },
                {
                    section: 'Business Objective',
                    notice: 'Select All That Apply',
                    multiple_choice: true,
                    group: 'business_obj',
                    details: [{
                        name: 'Sell a Product',
                        value: 'sell_product',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/sell.svg')
                    },
                    {
                        name: 'Generate Leads',
                        value: 'generate',
                        selected: false,
                        image: require('@/assets/icons/generate.svg')
                    },
                    {
                        name: 'Buy a Product',
                        value: 'buy',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/buy.svg')
                    },
                    {
                        name: 'Reach a Group of People',
                        value: 'reach',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/reach.svg')
                    },
                    {
                        name: 'Increase Brand Awareness',
                        value: 'increase_brand',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/increase_brand.svg')
                    },
                    {
                        name: 'Build Loyalty',
                        value: 'loyalty',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/loyalty.svg')
                    },
                    {
                        name: 'Increase Subscribers',
                        value: 'increase_subscribers',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/increase_subscribers.svg')
                    },
                    {
                        name: 'Increase Reviews',
                        value: 'increase_reviews',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/increase_reviews.svg')
                    },
                    {
                        name: 'Increase Referrals',
                        value: 'increase_referrals',
                        selected: false,
                        belongs_to: 'business_obj',
                        image: require('@/assets/icons/increase_referrals.svg')
                    }]
                },
                {
                    section: 'Audience',
                    notice: 'Optional',
                    multiple_choice: false,
                    group: 'audience',
                    details: [{
                        name: 'Reach People I Know',
                        value: 'reach_know',
                        selected: false,
                        belongs_to: 'audience',
                        image: require('@/assets/icons/reach_people.svg')
                    },
                    {
                        name: 'Reach People I Don\'t Know',
                        value: 'reach_dont_know',
                        selected: false,
                        belongs_to: 'audience',
                        image: require('@/assets/icons/reach_people.svg')
                    },
                    {
                        name: 'Reach Both',
                        value: 'reach_both',
                        selected: false,
                        belongs_to: 'audience',
                        image: require('@/assets/icons/reach_both.svg')
                    }]
                },
                {
                    section: 'Ad Type',
                    notice: 'It\'s okay, you can change your selections later.',
                    multiple_choice: true,
                    group: 'ad_type',
                    details: [{
                        name: 'Photo',
                        value: 'photo',
                        selected: false,
                        belongs_to: 'ad_type',
                        image: require('@/assets/icons/photo.svg')
                    },
                    {
                        name: 'Video',
                        value: 'video',
                        selected: false,
                        belongs_to: 'ad_type',
                        image: require('@/assets/icons/video.svg')
                    },
                    {
                        name: 'Slideshow',
                        value: 'slideshow',
                        selected: false,
                        belongs_to: 'ad_type',
                        image: require('@/assets/icons/slideshow.svg')
                    }]
                },
            ]
        };
    },
    created() {
        //preset filters
        this.filters.forEach(filter => {
            filter.details.forEach(e => {
                if (e.selected === true) {
                    Object.assign(this.selectedItems, { [e.value]: e.selected });
                }
            });
        });
    },
    methods: {
        //if some sections can have multiple choises than I think
        // they need to work like checkboxes and others like radiobuttons
        setSelection(value, multipleChoice, belongsTo) {
            if (!multipleChoice) {
                this.filters.forEach(filter => {
                   if (!filter.multipleChoice && filter.group === belongsTo) {
                       filter.details.forEach(e => {
                            const getValue = (e.value === value) ? e.selected = !e.selected : e.selected = false;
                            Object.assign(this.selectedItems, { [e.value]: getValue });
                       });
                   }
                });
            } else {
                this.filters.forEach(filter => {
                    filter.details.forEach(e => {
                        if (e.value === value) {
                            e.selected = !e.selected;
                            Object.assign(this.selectedItems, { [e.value]: e.selected });
                        }
                    });
                });
            }
        },
        submitSelections() {
            this.$router.push({ path: 'library-main', query: this.selectedItems });
        }
    }
};
</script>

<style lang="scss" scoped>
.selection{
    margin-top:60px;
    padding: 65px 54px 0 54px;
}
.selection__columns{
    display: flex;
}
.selection__right{
    max-width: 275px;
}
.selection__title{
    color: #00A2EA;
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 20px;
}
.seleciton-desc{
    font-size: 14px;
    line-height: 23px;
    color: #757575;
}
.selection__left{
    padding-left: 80px;
    box-sizing: border-box;
    width: calc(100% - 275px);
}
.selection__smalltitle{
    font-size: 20px;
    line-height: 28px;
    color: #00A2EA;
}
.selection__boxes{
    padding:15px;
}
.selection__notice{
    font-size: 14px;
    color: #757575;
    font-style: italic;
    margin-left: 15px;
    display: inline-block;
    transform: translateY(-1px);
}
.selection__row{
    max-width: 900px;
    margin-bottom: 30px;
}

.selection__footer{
    height: 75px;
    background: #E4EAED;
    width: calc(100% + 108px);
    margin-left: -54px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
}
.selection__submit{
    text-decoration: none;
    background: #03A2EA;
    color: white;
    border-radius: 5px;
    display:block;
    padding: 12px 0;
    width: 217px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight:600;
    &:hover{
       background: #7ED321;
    }
    &:active{
        transform: translateY(1px);
    }
}
//creative block
.creative{
    margin-top: 100px;
    box-sizing: border-box;
    padding: 25px 30px 40px 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    background: linear-gradient(210deg, #3C4245 0%, #3C4245 35%, #187AA6 75%,#187AA6 100%);
}
.creative__title{
    color: #00A2EA;
    font-size: 26px;
    line-height: 32px;
    white-space: nowrap;
    font-weight: 300;
    text-align: center;
    margin-bottom: 25px;
}
.creative__desc{
    color: white;
    font-size: 14px;
    line-height: 23px;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 65px;
}
.creative__link{
    text-decoration: none;
    background: #7ED321;
    color: white;
    border-radius: 5px;
    display:block;
    padding: 18px 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight:600;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.25);
    &:hover{
       background: #03A2EA;
    }
    &:active{
        transform: translateY(1px);
    }
}
//end creative
</style>
